<template lang="pug">
	v-btn(
		v-on="$listeners"
		:disabled="disabled"
		:min-width="$vuetify.breakpoint.smAndUp ? 140 : null"
		:loading="loading"
		:outlined="secondary"
		:color="color"
		:type="submit ? 'submit' : undefined"
		height="48"
		depressed
	).bigButton.overflow-hidden.px-6.px-md-9
		slot
</template>
<script lang="ts">
	import { Component, Prop, Vue } from "vue-property-decorator"

	@Component({components: {}})
	export default class TextButton extends Vue {
		@Prop({type: Boolean})
		public readonly disabled!: boolean
		@Prop({type: Boolean})
		public readonly loading!: boolean
		@Prop({type: Boolean})
		public readonly secondary!: boolean
		@Prop({type: Boolean})
		public readonly submit!: boolean
		@Prop({required: false})
		public readonly color!: string
	}
</script>
<style lang="sass" scoped>
	.v-btn.bigButton
		flex: 1
		color: $po_midnight
		+mq(sm)
			flex: none
		.flex-column &
			flex: auto
		&::before
			border-radius: 0
		&.v-btn--outlined
			border-color: $po_graylight
		&.v-btn--disabled
			color: $po_bluelight !important
			background-color: $po_grayevenlighter !important
</style>
