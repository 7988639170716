<template lang="pug">
	v-card(v-if="isOpen" flat tile).cookieConsent.d-flex.align-end
		v-toolbar(height="auto" flat)
			v-container.d-flex.flex-column.flex-md-row.justify-space-between.align-md-end.black--text.py-4.px-1.pt-sm-5.px-sm-2.pb-sm-6
				div
					.h3 {{$tk('header')}}
					.text-body-2  {{$tk('text')}}
					v-list(:class="{show}").content.overflow-hidden.pa-0
						v-list-item(v-for="cookie in cookies" :key="cookie.name").midnight--text.pa-0
							v-list-item-content
								v-list-item-title.text-body-2.font-weight-bold {{cookie.name}}
								div.text-body-2.bluedark--text {{cookie.description}}
				.d-flex.flex-column.flex-sm-row.align-sm-center.mb-1.ml-sm-auto
					v-btn(@click="show = !show" color="bluelight" min-width="0" x-large text).btn.align-self-start.my-5.my-sm-0.px-1.px-sm-6 {{$tk('learnMore')}}
					.d-flex
						v-btn(@click="deny" min-width="146px" color="graylightdarker" x-large depressed).btn.midnight--text.px-2 {{$tk("deny")}}
						v-btn(@click="allow" min-width="146px" color="primary" x-large depressed).btn.white--text.mr-0.px-2 {{$tk("allow")}}
				Transition(name="fade")
					v-btn(v-if="show" @click="show = false" width="36" height="36" icon).btn-close
						PoIcon(icon="close" size="14" color="midnight")
</template>
<script lang="ts">
	import {Component, I18nComponentMixin} from "@/mixins/combinations"
	import BasicDialog from "@/components/dialogs/basicDialog.vue"
	import Tracking from "@/managers/session/logging/tracking"
	import Authentication from "@/managers/authentication/authentication"
	import Navigation from "@/managers/session/navigation"

	@Component({components: {BasicDialog}})
	export default class CookieConsentDialog extends I18nComponentMixin {
		private show = false

		private get isOpen(): boolean {
			return !Tracking.hasUpdatedCanTrack && !Authentication.isLoggingIn && !Authentication.isAuthenticated && !Navigation.isRouting
		}

		private get cookies(): ICookie[] {
			return this.$tk("cookies") as any as ICookie[]
		}

		constructor() {
			super()
			this.i18nKey = "cookieConsentDialog"
		}

		private allow(): void {
			Tracking.updateCanTrackUnauthenticated(true)
		}

		private deny(): void {
			Tracking.updateCanTrackUnauthenticated(false)
		}
	}

	interface ICookie {
		name: string
		description: string
	}
</script>
<style lang="sass" scoped>
	.cookieConsent
		position: fixed
		inset: 0
		background: rgba($po_midnight, .2)
		z-index: 1000
		.container
			position: relative
			max-width: 1090px
			+mq(sm)
				gap: 20px
			.h3
				margin-bottom: 5px
				line-height: +rem(24)
				font-size: +rem(20)
			.content
				max-height: 0
				opacity: 0
				transition: all .3s cubic-bezier(0, 1, 0, 1)
				&.show
					max-height: 1000px
					opacity: 1
					transition: all .3s cubic-bezier(1, 0, 1, 0)
			.btn
				flex: 1
				margin: 0 6px 0 0
				border-radius: 6px
			.btn-close
				position: absolute
				inset: 4px -8px auto auto
				+mq(md)
					inset: 12px 4px auto auto
	.fade-enter,
	.fade-leave
		opacity: 0
	.fade-enter-active
		transition: opacity .2s $swing .3s
</style>
