import Vue from "vue"
import {watch} from "@/store/store"
import Portal from "@/managers/portal"
import Locations from "@/managers/location/locations"
import ContentSubscription from "@/managers/data/location/contentSubscription"

class ContentSubscriptions {
	private readonly state: {list: ContentSubscription[] | null}

	public get isReady(): boolean {
		return this.state.list !== null
	}

	public get hasAny(): boolean {
		return this.isReady && this.list.length > 0
	}

	public get list(): ContentSubscription[] {
		if (this.state.list === null)
			throw new Error("ContentSubscribers are not ready")

		return this.state.list
	}

	public get map(): Map<number, ContentSubscription> {
		return new Map(this.list.map(s => [s.id, s]))
	}

	constructor() {
		this.state = Vue.observable({ list: null })
		watch(
			() => Locations.main,
			location => {
				this.state.list = null
				if (location !== null)
					this.updateList(location.id)
			},
			{immediate: true}
		)
	}

	public update(): Promise<void> {
		return this.updateList(Locations.main!.id)
	}

	public async subscribeMain(ticket: string): Promise<void> {
		const locationId = Locations.main!.id
		await this.subscribe(locationId, ticket)

		if (locationId !== Locations.main?.id)
			return

		return this.updateList(locationId)
	}

	public subscribe(locationId: number, ticket: string): Promise<void> {
		return Portal.call.locations.contentSubscriptionsPost(locationId, ticket).response
	}

	public async delete(organizationId: number): Promise<void> {
		const locationId = Locations.main!.id
		await Portal.call.organizations.contentSubscribersDelete(organizationId, locationId).response

		if (locationId !== Locations.main?.id)
			return

		const index = this.state.list!.findIndex(s => s.id === organizationId)

		if (index !== -1)
			this.list.splice(index, 1)
	}

	private async updateList(locationId: number): Promise<void> {
		const data = await Portal.call.locations.contentSubscriptionsGet(locationId).response

		if (locationId !== Locations.main?.id)
			return

		this.state.list = data
			.map(d => ContentSubscription.fromApi(d.Organization))
	}
}

export {ContentSubscription}
export default new ContentSubscriptions()
