import type {IExternalOrganization} from "@chaosinsight/postoffice-portalclient"
import type {IItemNamed} from "@/managers/data/base/iItem"

export default class ContentSubscription implements IItemNamed {
	public id!: number
	public name!: string
	public logoUrl!: string

	private constructor() {
	}

	private setFromApi(data: IExternalOrganization): this {
		this.id = data.Id
		this.name = data.Name
		this.logoUrl = data.LogoUrl

		return this
	}

	public static fromApi(data: IExternalOrganization): ContentSubscription {
		return new ContentSubscription().setFromApi(data)
	}
}
