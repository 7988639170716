<template lang="pug">
	BasicDialog(
		:width="$vuetify.breakpoint.mdAndUp ? 614 : 500"
		:header="header"
		:isOpen.sync="innerIsOpen"
		:persistent="persistent"
		wideMargins
	)
		template(#activator="props")
			slot(name="activator" :on="props.on" :attrs="props.attrs")
		.text-center.px-md-10
			slot
		template(#actions)
			.d-flex.flex-grow-1.flex-sm-grow-0.mx-auto
				BigButton(:loading="isWorking" @click="cancelClicked" secondary).ma-1
					| {{cancel ? cancel : $t("common.cancel") | capitalize}}
				BigButton(v-if="canConfirm" :loading="isWorking" @click="confirmClicked").ma-1
					| {{confirm ? confirm : $t("common.yes") | capitalize}}
</template>
<script lang="ts">
	import { Component, Prop, Vue} from "vue-property-decorator"
	import BasicDialog from "@/components/dialogs/basicDialog.vue"
	import BigButton from "@/components/input/bigButton.vue"

	@Component({components: {BigButton, BasicDialog}})
	export default class ConfirmDialog extends Vue {
		@Prop({required: false})
		public readonly isOpen?: boolean
		@Prop({required: false})
		public readonly item?: unknown | null
		@Prop({required: true})
		public readonly header!: string
		@Prop({type: Boolean})
		public readonly persistent!: boolean
		@Prop({required: false})
		public readonly confirm?: string
		@Prop({required: false})
		public readonly cancel?: string
		@Prop({default: true})
		public readonly canConfirm!: boolean
		@Prop({default: false})
		public readonly isWorking!: boolean

		private innerIsOpenValue: boolean = false

		private get innerIsOpen(): boolean {
			if (this.item !== null && this.item !== undefined)
				return true

			if (this.isOpen !== undefined)
				return this.isOpen

			return this.innerIsOpenValue
		}

		private set innerIsOpen(value: boolean) {
			if (!value) {
				if (this.isOpen)
					this.$emit("update:isOpen", false)
				if (this.item !== null)
					this.$emit("update:item", null)
			}
			this.innerIsOpenValue = value
		}

		private confirmClicked(): void {
			this.$emit("confirmed", this.item)
			this.innerIsOpen = false
		}

		private cancelClicked(): void {
			this.$emit("canceled", this.item)
			this.innerIsOpen = false
		}
	}
</script>
